import React from 'react'
import { Container, Col, Row, Button } from 'react-bootstrap'
import * as classes from '../custom-styles/Service.module.css'
import DatabaseIcon from '../images/Database_Feature.svg'
import BillingIcon from '../images/Billing_Feature.svg'
import NetworkIcon from '../images/Network_Feature.svg'
import LocationIcon from '../images/Location_Feature.svg'
import styled from 'styled-components'

export default function Services(props) {

  const Features = styled.div`
    background-color:#F9FAFD;
    padding-top: 20px;
    padding-bottom: 40px;
  `;
  
  const ServicesHeading = styled.div`
    text-align:center !important;
    font-size: 50px !important;
    font-weight: 700 !important;
  }`;

  const BorderLine = styled.div`
    border-bottom: 8px solid #052475;
    width: 76px;
    margin: 25px auto;
  `;

  return <>
    <Features>
      <Container>
        <Row>
          <Col lg={6}>
            <div className='px-3'>
              <div className='py-4'>
                <div className={`${classes.servicesbox} d-flex`}>
                  <div className=''>
                    <div className={`d-flex align-items-center`}>
                      <div>
                        <DatabaseIcon/>
                      </div>
                      <div>
                        <div className='px-3'>
                          <h3 className={`${classes.servicesdataname}`}>Ametnes Platform</h3>
                          <p className={`${classes.servicessubheading}`}>Manage your private business data applications wherever you host them - in the cloud or on-premises</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className={`${classes.servicesbox} d-flex `}>
                  <div className=''>
                    <div className={`d-flex align-items-center`}>
                      <div>
                        <LocationIcon />
                      </div>
                      <div>
                        <div className='px-3'>
                          <h3 className={`${classes.servicesdataname}`}>Cloud Migrations</h3>
                          <p className={`${classes.servicessubheading}`}>Migrate your data to the cloud.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className='px-3'>
              <div className='py-4'>
                <div className={`${classes.servicesbox} d-flex `}>
                  <div className=''>
                    <div className={`d-flex align-items-center`}>
                      <div>
                        <NetworkIcon />
                      </div>
                      <div className={`d-flex`}>
                        <div className='px-3'>
                          <h3 className={`${classes.servicesdataname}`}>Automated Software Releases</h3>
                          <p className={`${classes.servicessubheading}`}>Automated software releases using modern automations - CI/CD.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className={`${classes.servicesbox} d-flex`}>
                  <div className=''>
                    <div className={`d-flex align-items-center`}>
                      <div>
                        <BillingIcon />
                      </div>
                      <div>
                        <div className='px-4'>
                          <h3 className={`${classes.servicesdataname}`}>Data Migrations</h3>
                          <ul className='p-0'>
                            <li className='m-0'>Migrate on-premise data into the cloud.</li>
                            <li className='m-0'>Move large datasets between clouds.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Features>
  </>
}
